/**
 * 沪深港通
 */

import React,{useState, useEffect} from "react"
import quote_data, {type_hsgt} from "../../../modules/quote_info/web";
import './index.less'
import { sendStat } from "../../../modules/bigdata_stat";

/**
 * 沪深港通-状态
 * @param props 
 * @returns 
 */
function HSGTState(props:{
  status:number,
  amtStatus: number,
  /** 是否是北向 沪股通 深股通 */
  is_bx: boolean
}){
  if(props.status == 1){
    //北向 额度
    // if(props.is_bx){
    //   if(props.amtStatus == 1){
    //     return (
    //       <b></b>
    //     )
    //   }
    // }
    return (
      <>
        <b></b>有额度
      </>
    )
  }
  if(props.status == 2){
    return (
      <>
        <b className="off"></b>无额度
      </>
    )
  }
  if(props.status == 3){
    return (
      <>
        <b className="off"></b>收盘
      </>
    )
  }
  if(props.status == 4){
    return (
      <>
        <b className="off"></b>休市
      </>
    )
  }
  
  return (
    <>-</>
  )
}

/**
 * 沪深港通数据格式化
 * @param money 
 * @returns 
 */
function FormatMoney(props:{
  money:any
}) {
  let money = props.money

  if(isNaN(money) || money === ''){
    return <>{money}</>
  }

  if(money == 0){
    return <>-</>
  }

  money = money * 10000

  var hz = '';
  if(money >= 100000000||money <= -100000000){
    money = money / 100000000;
    hz = '亿';
  }
  else if(money >= 10000||money <= -10000){
    money = money / 10000;
    hz = '万';
  }

  let number_class = ''
  if(money > 0){
    number_class = 'price_up'
  }
  else if(money < 0){
    number_class = 'price_down'
  }

  return (
    <>
      <a href="http://data.eastmoney.com/hsgt/index.html" target="_blank"><span className={`nums ${number_class}`}>{money.toFixed(2)}</span>{hz}</a>
    </>
  )
}

/**
 * 沪深港通资金流入
 * @param props 
 */
function HSGT_LR(props:{
  is_bx: boolean,
  data:{
    status: number,
    amtStatus: number,
    buySellAmt: any,
    dayAmtRemain: number,
    buySellAmtDate: number,
    dayNetAmtIn: number,
    netBuyAmt: number,
    date2: string
  }
}){


  if(!props.is_bx){
    if(props.data.status == 4){
      return (
        <>
          <a href="http://data.eastmoney.com/hsgt/index.html" target="_blank" className="zjlink">净买额</a>
          -
        </>
      )
    }
    return (
      <>
         <a href="http://data.eastmoney.com/hsgt/index.html" target="_blank" className="zjlink">净买额</a>
         <FormatMoney money={props.data.netBuyAmt}></FormatMoney>
      </>
    )    
  }

  //盘后显示资金流
  if(props.is_bx && props.data.status == 3 && (props.data.dayAmtRemain > 0 || (props.data.dayAmtRemain == 0 && props.data.amtStatus == 3))){
    <>
      <a href="http://data.eastmoney.com/hsgt/index.html" target="_blank" className="zjlink">资金流入</a>
      <FormatMoney money={props.data.dayNetAmtIn}></FormatMoney>
    </>
  }

  // if(props.is_bx && props.amtStatus == 1 && (props.status == 1 || (props.status == 3 && !(props.buySellAmt && (props.buySellAmtDate?.toString() == props.date2.replace(/-/g, ''))) ))){
  //   return (
  //     <>
  //        <a href="http://data.eastmoney.com/hsgt/index.html" target="_blank" className="zjlink">当日额度充足</a>
  //     </>
  //   )
  // }

  if(props.is_bx && props.data.amtStatus == 1 && (props.data.status == 1 || props.data.status == 3 )){
    return (
      <>
         <a href="http://data.eastmoney.com/hsgt/index.html" target="_blank" className="zjlink">当日额度充足</a>
      </>
    )
  }
 
  if(props.data.status == 2 && props.is_bx){
    return (
      <>
         <a href="http://data.eastmoney.com/hsgt/index.html" target="_blank" className="zjlink">当日额度耗尽</a>
      </>
    )
  }  

  return (
    <>
      <a href="http://data.eastmoney.com/hsgt/index.html" target="_blank" className="zjlink">资金流入</a>
      {props.data.status == 4 ?
        <>-</>
      :
        <FormatMoney money={props.data.dayNetAmtIn}></FormatMoney>
      }
    </>
  )
}

function HSGT_LR_Blank(){
  return(
    <>
      <a href="http://data.eastmoney.com/hsgt/index.html" target="_blank" className="zjlink">资金流入</a>
        <>-</>
    </>
  )
}

/** 沪深港通 */
function HSGT(){

  const [showdata, SetShowData] = useState(null as null | type_hsgt)

  /** 获取数据 */
  const gethgts = async()=>{
    let back = await quote_data.hsgt()
    if(back != null){
      SetShowData(back)
    }    
  }

  useEffect(()=>{
    gethgts()

    let cd = setInterval(function(){
      gethgts()
    }, 60 * 1000)

    return ()=>{
      try {
        clearInterval(cd)
      } catch (error) {
        
      }
    }
  }, [])

  return (
    <div className="quote_hsgt">
      {showdata != null &&
        <>
          <table>
            <tbody>
              <tr>
                <td onClick={()=>{sendStat('TD', 'click', 'web.xgnhqdy.hgt');}}>
                  <a href="//quote.eastmoney.com/center/gridlist.html#sh_hk_board" target="_blank" className="sg">沪股通</a>
                  <HSGTState status={showdata.hk2sh.status} amtStatus={showdata.hk2sh.amtStatus} is_bx={true}></HSGTState>
                  <HSGT_LR data={showdata.hk2sh} is_bx={true}></HSGT_LR>                  
                </td>
                <td onClick={()=>{sendStat('TD', 'click', 'web.xgnhqdy.sgt');}}>
                  <a href="//quote.eastmoney.com/center/gridlist.html#sz_hk_board" target="_blank" className="sg">深股通</a>
                  <HSGTState status={showdata.hk2sz.status} amtStatus={showdata.hk2sz.amtStatus} is_bx={true}></HSGTState>
                  <HSGT_LR data={showdata.hk2sz} is_bx={true}></HSGT_LR>
                </td>
                <td onClick={()=>{sendStat('TD', 'click', 'web.xgnhqdy.hggt');}}>
                  <a href="//quote.eastmoney.com/center/gridlist.html#hk_sh_stocks" target="_blank" className="sg">港股通(沪)</a>
                  <HSGTState status={showdata.sh2hk.status} amtStatus={showdata.sh2hk.amtStatus} is_bx={false}></HSGTState>
                  <HSGT_LR data={showdata.sh2hk} is_bx={false}></HSGT_LR>
                </td>
                <td onClick={()=>{sendStat('TD', 'click', 'web.xgnhqdy.sggt');}}>
                  <a href="//quote.eastmoney.com/center/gridlist.html#hk_sz_stocks" target="_blank" className="sg">港股通(深)</a>
                  <HSGTState status={showdata.sz2hk.status} amtStatus={showdata.sz2hk.amtStatus} is_bx={false}></HSGTState>
                  <HSGT_LR data={showdata.sz2hk} is_bx={false}></HSGT_LR>
                </td>
              </tr>
            </tbody>
          </table>     
        </>
      }
      {showdata == null &&
        <>
          <table>
            <tbody>
              <tr>
                <td>
                  <a href="//quote.eastmoney.com/center/gridlist.html#sh_hk_board" target="_blank" className="sg">沪股通</a>
                  <HSGTState status={999} amtStatus={999} is_bx={false}></HSGTState>
                  <HSGT_LR_Blank></HSGT_LR_Blank>
                </td>
                <td>
                  <a href="//quote.eastmoney.com/center/gridlist.html#sz_hk_board" target="_blank" className="sg">深股通</a>
                  <HSGTState status={999} amtStatus={999} is_bx={false}></HSGTState>
                  <HSGT_LR_Blank></HSGT_LR_Blank>
                </td>
                <td>
                  <a href="//quote.eastmoney.com/center/gridlist.html#hk_sh_stocks" target="_blank" className="sg">港股通(沪)</a>
                  <HSGTState status={999} amtStatus={999} is_bx={false}></HSGTState>
                  <HSGT_LR_Blank></HSGT_LR_Blank>
                </td>
                <td>
                  <a href="//quote.eastmoney.com/center/gridlist.html#hk_sz_stocks" target="_blank" className="sg">港股通(深)</a>
                  <HSGTState status={999} amtStatus={999} is_bx={false}></HSGTState>
                  <HSGT_LR_Blank></HSGT_LR_Blank>
                </td>
              </tr>
            </tbody>
          </table>     
        </>
      }
    </div>
  )
}

export default HSGT