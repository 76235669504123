import React,{useState, useEffect} from "react"
import { getQuote4fw, initSingleValue } from "../../../modules/quote_info/quote_data";
import { QuoteItem } from "../../../modules/quote_info/config";
import { SiderTabBox } from "../../shared/sider_tab_box";
import './index.less'

/**
 * 港股财务分析-4分位
 * @returns 
 */
export function HKFinance4(props:{
  quotecode: string
}){
  return (
    <SiderTabBox {...{
      boxdata:[
        {
          tab_html: <i>财务数据分析</i>,
          more_html: <i>鼠标移入四分位图标可查看解释说明</i>
        }
      ]}}>
        <HKFinance4Table quotecode={props.quotecode}></HKFinance4Table>
      </SiderTabBox> 
    
  )
}

/**
 * A股四分位
 * @param props 
 * @returns 
 */
export function AFinance4Table(props:{
  name: string,
  code: string,
  quotecode: string,
  bk_id: string,
  bk_name: string
}){

  const [showdata, SetShowData] = useState([
    initSingleValue(),
    initSingleValue()
  ])

  useEffect(()=>{
    ;(async()=>{
      let data = await getQuote4fw(props.quotecode, [
        QuoteItem.行情统一链接,
        QuoteItem.总市值,
        QuoteItem.股东权益,
        QuoteItem.净利润,
        QuoteItem.总营业收入TTM,
        QuoteItem.市盈率动态,
        QuoteItem.市净率,
        QuoteItem.毛利率,
        QuoteItem.市现率TTM,
        QuoteItem.净资产收益率TTM,
        QuoteItem.股息率,
        QuoteItem.行业板块的成分股数,
        QuoteItem.净资产,
        QuoteItem.净利率,
        QuoteItem.净资产收益率ROE,

        QuoteItem.总市值行业排名,
        QuoteItem.净资产行业排名,
        QuoteItem.净利润行业排名,
        QuoteItem.市盈率动态行业排名,
        QuoteItem.市盈率TTM行业排名,
        QuoteItem.市净率行业排名,
        QuoteItem.毛利率行业排名,
        QuoteItem.净利率行业排名,
        QuoteItem.ROE行业排名,
        QuoteItem.ROETTM行业排名,
        QuoteItem.股东权益行业排名,
        QuoteItem.总营业收入TTM行业排名,
        QuoteItem.市销率TTM行业排名,
        QuoteItem.市现率TTM行业排名,
        QuoteItem.股息率行业排名,

        QuoteItem.总市值行业排名四分位,
        QuoteItem.净资产行业排名四分位,
        QuoteItem.净利润行业排名四分位,
        QuoteItem.净利润TTM行业排名四分位,
        QuoteItem.市盈率动态行业排名四分位,
        QuoteItem.市盈率TTM行业排名四分位,
        QuoteItem.市净率行业排名四分位,
        QuoteItem.毛利率行业排名四分位,
        QuoteItem.净利率行业排名四分位,
        QuoteItem.ROE行业排名四分位,
        QuoteItem.ROETTM行业排名四分位,
        QuoteItem.股东权益行业排名四分位,
        QuoteItem.总营业收入TTM行业排名四分位,
        QuoteItem.市销率TTM行业排名四分位,
        QuoteItem.市现率TTM行业排名四分位,
        QuoteItem.股息率行业排名四分位,

        QuoteItem.总市值行业平均,
        QuoteItem.净资产行业平均,
        QuoteItem.净利润行业平均,
        QuoteItem.市盈率动态行业平均,
        QuoteItem.市净率行业平均,
        QuoteItem.毛利率行业平均,
        QuoteItem.净利率行业平均,
        QuoteItem.ROE行业平均,
      ])
      // console.info(data)
      if(data.length == 2){
        SetShowData(data)
      }
    })();
  }, [])

  return (
    <div className="finance4 afinance4">
      <table>
        <thead>
          <tr>
            <th></th>
            <th>总市值</th>
            <th>净资产</th>
            <th>净利润</th>
            <th>市盈率(动)</th>
            <th>市净率</th>
            <th>毛利率</th>
            <th>净利率</th>
            <th>ROE<span className="title_help" title="加权净资产收益率"></span></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="ltitle"><a href={`//data.eastmoney.com/stockdata/${props.code}.html`} target="_blank">{props.name}</a></td>
            <td>{showdata[0][QuoteItem.总市值].txt}</td>
            <td>{showdata[0][QuoteItem.股东权益].txt}</td>
            <td>{showdata[0][QuoteItem.净利润].txt}</td>
            <td>{showdata[0][QuoteItem.市盈率动态].source < 0 ? '负值' : showdata[0][QuoteItem.市盈率动态].txt}</td>
            <td>{showdata[0][QuoteItem.市净率].source < 0 ? '负值' : showdata[0][QuoteItem.市净率].txt}</td>
            <td>{showdata[0][QuoteItem.毛利率].txt}</td>
            <td>{showdata[0][QuoteItem.净利率].txt}</td>
            <td>{showdata[0][QuoteItem.净资产收益率ROE].txt}</td>
          </tr>
          <tr>
            <td className="ltitle">
              {
                props.bk_id && props.bk_name &&
                <div className="hypj_hy"><a href={`//quote.eastmoney.com/center/boardlist.html#boards2-90.${props.bk_id}`} target="_blank">{props.bk_name}</a></div>
              } 
              <div className="hypj_hy2">(行业平均)</div>
            </td>
            <td>{showdata[1][QuoteItem.总市值行业平均].txt}</td>
            <td>{showdata[1][QuoteItem.净资产行业平均].txt}</td>
            <td>{showdata[1][QuoteItem.净利润行业平均].txt}</td>
            <td>{showdata[1][QuoteItem.市盈率动态行业平均].txt}</td>
            <td>{showdata[1][QuoteItem.市净率行业平均].txt}</td>
            <td>{showdata[1][QuoteItem.毛利率行业平均].txt}</td>
            <td>{showdata[1][QuoteItem.净利率行业平均].txt}</td>
            <td>{showdata[1][QuoteItem.ROE行业平均].txt}</td>
          </tr>
          <tr>
            <td className="ltitle">行业排名</td>
            <td>{showdata[0][QuoteItem.总市值行业排名].txt}|{showdata[1][QuoteItem.行业板块的成分股数].txt}</td>
            <td>{showdata[0][QuoteItem.净资产行业排名].txt}|{showdata[1][QuoteItem.行业板块的成分股数].txt}</td>
            <td>{showdata[0][QuoteItem.净利润行业排名].txt}|{showdata[1][QuoteItem.行业板块的成分股数].txt}</td>
            <td>{showdata[0][QuoteItem.市盈率动态].source < 0 || showdata[0][QuoteItem.市盈率动态].source == '-' ? '-' : showdata[0][QuoteItem.市盈率动态行业排名].txt}|{showdata[1][QuoteItem.行业板块的成分股数].txt}</td>
            <td>{showdata[0][QuoteItem.市净率].source < 0 || showdata[0][QuoteItem.市净率].source == '-' ? '-' : showdata[0][QuoteItem.市净率行业排名].txt}|{showdata[1][QuoteItem.行业板块的成分股数].txt}</td>
            <td>{showdata[0][QuoteItem.毛利率行业排名].txt}|{showdata[1][QuoteItem.行业板块的成分股数].txt}</td>
            <td>{showdata[0][QuoteItem.净利率行业排名].txt}|{showdata[1][QuoteItem.行业板块的成分股数].txt}</td>
            <td>{showdata[0][QuoteItem.ROE行业排名].txt}|{showdata[1][QuoteItem.行业板块的成分股数].txt}</td>
          </tr>
          <tr className="fw4tr">
            <td className="ltitle">
              四分位属性
              <span className="fw4tips">
                <div className="tip">
                  四分位属性是指根据每个指标的属性，进行数值大小排序，然后分为四等分，每个部分大约包含排名的四分之一。将属性分为高、较高、较低、低四类。<div className="red">注：鼠标移至四分位图标上时，会出现每个指标的说明和用途。</div>
                </div>
              </span>
            </td>
            <td><FW4 level={showdata[0][QuoteItem.总市值行业排名四分位].source} tip_type={1}></FW4></td>
            <td><FW4 level={showdata[0][QuoteItem.净资产行业排名四分位].source} tip_type={11}></FW4></td>
            <td><FW4 level={showdata[0][QuoteItem.净利润行业排名四分位].source} tip_type={12}></FW4></td>
            <td><FW4 level={showdata[0][QuoteItem.市盈率动态行业排名四分位].source} reversal={true} tip_type={13} noshow_title={showdata[0][QuoteItem.市盈率动态].source < 0 ? '市盈率动态':''} nodata={showdata[0][QuoteItem.市盈率动态].source == '-'}></FW4></td>
            <td><FW4 level={showdata[0][QuoteItem.市净率行业排名四分位].source} reversal={true} tip_type={6} noshow_title={showdata[0][QuoteItem.市净率].source < 0 ? '市净率':''} nodata={showdata[0][QuoteItem.市净率].source == '-'}></FW4></td>
            <td><FW4 level={showdata[0][QuoteItem.毛利率行业排名四分位].source} tip_type={14}></FW4></td>
            <td><FW4 level={showdata[0][QuoteItem.净利率行业排名四分位].source} tip_type={15}></FW4></td>
            <td><FW4 level={showdata[0][QuoteItem.ROE行业排名四分位].source} tip_type={16}></FW4></td>
          </tr>
        </tbody>
      </table>
    </div>    
  )
}

export function HKFinance4Table(props:{
  quotecode: string
}){

  const [showdata, SetShowData] = useState([
    initSingleValue(),
    initSingleValue()
  ])

  useEffect(()=>{
    ;(async()=>{
      let data = await getQuote4fw(props.quotecode, [
        QuoteItem.行情统一链接,
        QuoteItem.总市值,
        QuoteItem.股东权益,
        QuoteItem.净利润TTM,
        QuoteItem.总营业收入TTM,
        QuoteItem.市盈率TTM,
        QuoteItem.市净率,
        QuoteItem.市销率TTM,
        QuoteItem.市现率TTM,
        QuoteItem.净资产收益率TTM,
        QuoteItem.股息率,
        QuoteItem.行业板块的成分股数,

        QuoteItem.总市值行业排名,
        QuoteItem.净资产行业排名,
        QuoteItem.净利润行业排名,
        QuoteItem.净利润TTM行业排名,
        QuoteItem.市盈率动态行业排名,
        QuoteItem.市盈率TTM行业排名,
        QuoteItem.市净率行业排名,
        QuoteItem.毛利率行业排名,
        QuoteItem.净利率行业排名,
        QuoteItem.ROE行业排名,
        QuoteItem.ROETTM行业排名,
        QuoteItem.股东权益行业排名,
        QuoteItem.总营业收入TTM行业排名,
        QuoteItem.市销率TTM行业排名,
        QuoteItem.市现率TTM行业排名,
        QuoteItem.股息率行业排名,

        QuoteItem.总市值行业排名四分位,
        QuoteItem.净资产行业排名四分位,
        QuoteItem.净利润行业排名四分位,
        QuoteItem.净利润TTM行业排名四分位,
        QuoteItem.市盈率动态行业排名四分位,
        QuoteItem.市盈率TTM行业排名四分位,
        QuoteItem.市净率行业排名四分位,
        QuoteItem.毛利率行业排名四分位,
        QuoteItem.净利率行业排名四分位,
        QuoteItem.ROE行业排名四分位,
        QuoteItem.ROETTM行业排名四分位,
        QuoteItem.股东权益行业排名四分位,
        QuoteItem.总营业收入TTM行业排名四分位,
        QuoteItem.市销率TTM行业排名四分位,
        QuoteItem.市现率TTM行业排名四分位,
        QuoteItem.股息率行业排名四分位,
      ])
      // console.info(data)
      if(data.length == 2){
        SetShowData(data)
      }
    })();
  }, [])

  return (
    <div className="finance4">
      <table>
        <thead>
          <tr>
            <th></th>
            <th>总市值</th>
            <th>股东权益</th>
            <th>净利润TTM</th>
            <th>总营业收入TTM</th>
            <th>市盈率TTM</th>
            <th>市净率</th>
            <th>市销率TTM</th>
            <th>市现率TTM</th>
            <th>净资产收益率TTM</th>
            <th>股息率</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="ltitle">{showdata[0][QuoteItem.行情统一链接].html}</td>
            <td>{showdata[0][QuoteItem.总市值].txt}</td>
            <td>{showdata[0][QuoteItem.股东权益].txt}</td>
            <td>{showdata[0][QuoteItem.净利润TTM].txt}</td>
            <td>{showdata[0][QuoteItem.总营业收入TTM].txt}</td>
            <td>{showdata[0][QuoteItem.市盈率TTM].source < 0 ? '负值' : showdata[0][QuoteItem.市盈率TTM].txt}</td>
            <td>{showdata[0][QuoteItem.市净率].source < 0 ? '负值' : showdata[0][QuoteItem.市净率].txt}</td>
            <td>{showdata[0][QuoteItem.市销率TTM].source < 0 ? '负值' : showdata[0][QuoteItem.市销率TTM].txt}</td>
            <td>{showdata[0][QuoteItem.市现率TTM].source < 0 ? '负值' : showdata[0][QuoteItem.市现率TTM].txt}</td>
            <td>{showdata[0][QuoteItem.净资产收益率TTM].txt}</td>
            <td>{showdata[0][QuoteItem.股息率].txt}</td>
          </tr>
          <tr>
            <td className="ltitle">行业排名</td>
            <td>{showdata[0][QuoteItem.总市值行业排名].txt}|{showdata[1][QuoteItem.行业板块的成分股数].txt}</td>
            <td>{showdata[0][QuoteItem.股东权益行业排名].txt}|{showdata[1][QuoteItem.行业板块的成分股数].txt}</td>
            <td>{showdata[0][QuoteItem.净利润TTM行业排名].txt}|{showdata[1][QuoteItem.行业板块的成分股数].txt}</td>
            <td>{showdata[0][QuoteItem.总营业收入TTM行业排名].txt}|{showdata[1][QuoteItem.行业板块的成分股数].txt}</td>
            <td>{showdata[0][QuoteItem.市盈率TTM].source < 0 ? '-' : showdata[0][QuoteItem.市盈率TTM行业排名].txt}|{showdata[1][QuoteItem.行业板块的成分股数].txt}</td>
            <td>{showdata[0][QuoteItem.市净率].source < 0 ? '-' : showdata[0][QuoteItem.市净率行业排名].txt}|{showdata[1][QuoteItem.行业板块的成分股数].txt}</td>
            <td>{showdata[0][QuoteItem.市销率TTM].source < 0 ? '-' : showdata[0][QuoteItem.市销率TTM行业排名].txt}|{showdata[1][QuoteItem.行业板块的成分股数].txt}</td>
            <td>{showdata[0][QuoteItem.市现率TTM].source < 0 ? '-' : showdata[0][QuoteItem.市现率TTM行业排名].txt}|{showdata[1][QuoteItem.行业板块的成分股数].txt}</td>
            <td>{showdata[0][QuoteItem.ROETTM行业排名].txt}|{showdata[1][QuoteItem.行业板块的成分股数].txt}</td>
            <td>{showdata[0][QuoteItem.股息率行业排名].txt}|{showdata[1][QuoteItem.行业板块的成分股数].txt}</td>
          </tr>
          <tr className="fw4tr">
            <td className="ltitle">
              四分位属性
              <span className="fw4tips">
                <div className="tip">
                  四分位属性是指根据每个指标的属性，进行数值大小排序，然后分为四等分，每个部分大约包含排名的四分之一。将属性分为高、较高、较低、低四类。<div className="red">注：鼠标移至四分位图标上时，会出现每个指标的说明和用途。</div>
                </div>
              </span>
            </td>
            <td><FW4 level={showdata[0][QuoteItem.总市值行业排名四分位].source} tip_type={1}></FW4></td>
            <td><FW4 level={showdata[0][QuoteItem.股东权益行业排名四分位].source} tip_type={2}></FW4></td>
            <td><FW4 level={showdata[0][QuoteItem.净利润TTM行业排名四分位].source} tip_type={3}></FW4></td>
            <td><FW4 level={showdata[0][QuoteItem.总营业收入TTM行业排名四分位].source} tip_type={4}></FW4></td>
            <td><FW4 level={showdata[0][QuoteItem.市盈率TTM行业排名四分位].source} reversal={true} tip_type={5} noshow_title={showdata[0][QuoteItem.市盈率TTM].source < 0 ? '市盈率TTM':''}></FW4></td>
            <td><FW4 level={showdata[0][QuoteItem.市净率行业排名四分位].source} reversal={true} tip_type={6} noshow_title={showdata[0][QuoteItem.市净率].source < 0 ? '市净率':''}></FW4></td>
            <td><FW4 level={showdata[0][QuoteItem.市销率TTM行业排名四分位].source} reversal={true} tip_type={7} noshow_title={showdata[0][QuoteItem.市销率TTM].source < 0 ? '市销率TTM':''}></FW4></td>
            <td><FW4 level={showdata[0][QuoteItem.市现率TTM行业排名四分位].source} reversal={true} tip_type={8} noshow_title={showdata[0][QuoteItem.市现率TTM].source < 0 ? '市现率TTM':''}></FW4></td>
            <td><FW4 level={showdata[0][QuoteItem.ROETTM行业排名四分位].source} tip_type={9}></FW4></td>
            <td><FW4 level={showdata[0][QuoteItem.股息率行业排名四分位].source} tip_type={10}></FW4></td>
          </tr>
        </tbody>
      </table>
    </div>    
  )
}

/** 4分位 */
function FW4(props:{
  level: number, //级别 1 高 2 较高 3 较低 4 低
  reversal?: boolean, // 反转   市盈率	市净率	市销率	市现率 需要反转，并且负值不能参加判断
  tip_type: number, //提示类型
  noshow_title?: string, //市盈率	市净率	市销率	市现率 负值不能参加判断的提示文字
  nodata?:boolean
}){

  let level = props.level

  // console.info(level)
  

  if(props.noshow_title){
    level = 0
  }

  if(props.reversal == true){
    level = 5 - level
  }

  let level_txt = '-'
  switch (props.level) {
    case 1:
      level_txt = '高'
      break;
    case 2:
      level_txt = '较高'
      break
    case 3:
      level_txt = '较低'
      break;
    case 4:
      level_txt = '低'
      break
  }

  return (
    <div className="fw4">
      <div className="bar">
        {
          isNaN(level) || props.nodata ?
            <ul>
              <li className='nobg'></li>
              <li className='nobg'></li>
              <li className='nobg'></li>
              <li className='nobg'></li>
            </ul>
          :
            <ul>
              <li className={level > 1 ? 'nobg' : ''}></li>
              <li className={level > 2 ? 'nobg' : ''}></li>
              <li className={level > 3 ? 'nobg' : ''}></li>
              <li className={level > 4 ? 'nobg' : ''}></li>
            </ul>
        }
        {
          props.tip_type == 1 &&
          <div className="tip">
            总市值计算公式为公司总股本乘以市价。该指标侧面反映出一家公司的规模和行业地位。总市值越大，公司规模越大，相应的行业地位也越高。
            <div className="red">注：四分位属性以行业排名为比较基准。</div>
          </div>        
        }
        {
          props.tip_type == 2 &&
          <div className="tip">
            股东权益又名净资产，是资产总额减去负债总额后的净额。该指标反映企业所有者在企业中的财产价值。股东权益越大，信用风险越低。
            <div className="red">注：四分位属性以行业排名为比较基准。</div>
          </div>        
        }
        {
          props.tip_type == 3 &&
          <div className="tip">
            净利润又称税后利润，计算公司为利润总额减去所得税费用。净利润是一个企业经营的最终成果，是衡量一个企业经营效益的主要指标。
            <div className="red">注：四分位属性以行业排名为比较基准。</div>
          </div>        
        }
        {
          props.tip_type == 4 &&
          <div className="tip">
            总营业收入指主营业务收入与其他业务收入之和，是企业取得利润的重要保障，是企业现金流入量的重要组成部分。
            <div className="red">注：四分位属性以行业排名为比较基准。</div>
          </div>        
        }      
        {
          props.tip_type == 5 &&
          <div className="tip">
            市盈率是公司股票价格与每股收益的比率。该指标主要衡量公司的价值，高市盈率一般由高成长支撑着。市盈率越低，股票越便宜，相对投资价值越大。
            <div className="red">注：四分位属性以行业排名为比较基准。</div>
          </div>        
        }
        {
          props.tip_type == 6 &&
          <div className="tip">
            市净率是公司股票价格与每股净资产的比率。市净率越低，每股内含净资产值越高，投资价值越高。
            <div className="red">注：四分位属性以行业排名为比较基准。</div>
          </div>        
        }
        {
          props.tip_type == 7 &&
          <div className="tip">
            市销率是股票价格与每股销售额的比率，市销率越低，说明该公司股票目前的投资价值越大。
            <div className="red">注：四分位属性以行业排名为比较基准。</div>
          </div>        
        }
        {
          props.tip_type == 8 &&
          <div className="tip">
            市现率是股票价格与每股现金流量的比率。市现率可用于评价股票的价格水平和风险水平。市现率越小，表明上市公司的每股现金增加额越多，经营压力越小。
            <div className="red">注：四分位属性以行业排名为比较基准。</div>
          </div>        
        }
        {
          props.tip_type == 9 &&
          <div className="tip">
            净资产收益率是公司税后利润除以净资产的比率，该指标体现了自有资本获得净收益的能力。指标值越高，说明投资带来的收益越高。
            <div className="red">注：四分位属性以行业排名为比较基准。</div>
          </div>        
        }
        {
          props.tip_type == 10 &&
          <div className="tip">
            股息率是股息与股票价格的比率。股息率是衡量企业是否具有投资价值的重要标尺之一，是投资收益率的简化形式。
            <div className="red">注：四分位属性以行业排名为比较基准</div>
          </div>        
        }
        {
          props.tip_type == 11 &&
          <div className="tip">
            公式为资产总额减去负债后的净额。该指标由实收资本、资本公积、盈余公积和未分配利润等构成，反映企业所有者在企业中的财产价值。净资产越大，信用风险越低。
            <div className="red">注：四分位属性以行业排名为比较基准</div>
          </div>        
        }
        {
          props.tip_type == 12 &&
          <div className="tip">
            公式为：净利润=利润总额-所得税费用。净利润是一个企业经营的最终成果，净利润多，企业的经营效益就好。
            <div className="red">注：四分位属性以行业排名为比较基准</div>
          </div>        
        }        
        {
          props.tip_type == 13 &&
          <div className="tip">
            公式为公司股票价格除以每股利润。该指标主要是衡量公司的价值，高市盈率一般是由高成长支撑着。市盈率越低，股票越便宜，相对投资价值越大。
            <div className="red">注：四分位属性以行业排名为比较基准</div>
          </div>        
        }     
        {
          props.tip_type == 14 &&
          <div className="tip">
            公式为毛利与销售收入的比率。毛利率越高，公司产品附加值越高，赚钱效率越高。
            <div className="red">注：四分位属性以行业排名为比较基准</div>
          </div>        
        }   
        {
          props.tip_type == 15 &&
          <div className="tip">
            公式为净利润与主营业务收入的比率。该指标表示企业每单位资产能获得净利润的数量，这一比率越高，说明企业全部资产的盈利能力越强。
            <div className="red">注：四分位属性以行业排名为比较基准</div>
          </div>        
        }          
        {
          props.tip_type == 16 &&
          <div className="tip">
            公式为税后利润与净资产的比率。该指标反映股东权益的收益水平，用以衡量公司运用自有资本的效率。指标值越高，说明投资带来的收益越高。
            <div className="red">注：四分位属性以行业排名为比较基准</div>
          </div>        
        }                   
        
      </div>
      {
        props.nodata ?
          <span>-</span>
        :
          props.noshow_title ?
            <span className="nodatatip" title={`${props.noshow_title}为负值，不参与四分位排名`}>- </span>
          :
            <>{level_txt}</>
      }
      
  
    </div>
  )
}