/**
 * 数据中心接口
 */

import api_url from "../../config/web";
import _, { toUpper } from 'lodash'
import txt from "../txt";
import dayjs from 'dayjs'
import { StockType } from "../stocktype";

/**
 * 获取债券信息_新接口
 * @param datetime 日期 格式:YYYY-MM-DD
 * @returns 
 */
export async function getBondInfoNew(market:string, code:string, is_bj_bond = 0):Promise<null|{
  SSDD: string,
  SECUCODE: string,
  SECURITY_CODE: string,
  BOND_NAME_ABBR: string,
  BOND_NAME: string,
  ISSUE_SCALE: number,
  ISSUE_PRICE: number,
  BOND_EXPIRE: string,
  NEWRATE_REFETENCE: number,
  ISSUE_DATE: string,
  LISTING_DATE: string,
  VALUE_DATE: string,
  EXPIRE_DATE: string,
  ISSUE_NAME: string,
  PAY_INTEREST_WAY: string,
  PI_RATE_TYPE: string,
  PAY_DAY: null | string,
  CURRENCY: string,
  DELIST_DATE: null | string,
  HONOUR_DATE: string,
  BOND_TYPE: string,
  PAY_INTEREST_TIMES: null | string,
  TRADE_MARKET_CODE: string,
  REMAIN_DAY3: string,
  REMAIN_DAY: string,
  CEASE_DATE: string,
  SECURITY_INNER_CODE: string,
}>{
  try {
    let marketstr = 'SZ'
    if(market == '1') marketstr = 'SH'
    if(is_bj_bond == 1) marketstr = 'BJ'

    let back = await $.ajax({
      url: `${api_url.getUrl('datacenter4')}api/data/v1/get?callback=?`,
      type: 'GET',
      dataType: 'json',
      data: {
        reportName: 'RPT_BOND_BS_INFO',
        columns: 'SSDD,SECUCODE,SECURITY_CODE,BOND_NAME_ABBR,BOND_NAME,ISSUE_SCALE,ISSUE_PRICE,BOND_EXPIRE,NEWRATE_REFETENCE,ISSUE_DATE,LISTING_DATE,VALUE_DATE,EXPIRE_DATE,ISSUE_NAME,PAY_INTEREST_WAY,PI_RATE_TYPE,PAY_DAY,CURRENCY,DELIST_DATE,HONOUR_DATE,BOND_TYPE,PAY_INTEREST_TIMES,TRADE_MARKET_CODE,REMAIN_DAY,REMAIN_DAY3,CEASE_DATE,SECURITY_INNER_CODE',
        filter: `(SECUCODE="${code}.${marketstr}")`,
        pageNumber: 1,
        pageSize: 200,
        sortTypes: '',
        source: `QuoteWeb`,
        client: `WEB`,        
      }
    })

    if(back?.result?.data instanceof Array && back.result.data.length > 0){
      return back.result.data[0]
    }    
  } catch (error) {
    
  }

  return null
}

/**
 * 获取债券信息
 * @param market 市场
 * @param code 代码
 */
export async function getBondInfo(market:string, code:string):Promise<null|{
  BONDCODE: string,
  SECURITYVARIETYCODE: string,
  SNAME: string,
  FNAME: string,
  ISSUEVOL: number,
  ISSUEPRICE: number,
  BONDPERIOD: number,
  COUPONRATE: number,
  TEXCH: string,
  TRADEMARKETCODE: string,
  ISSUEDATE: string,
  LISTDATE: string,
  DELISTDATE: string,
  FRSTVALUEDATE: string,
  LASTVALUEDATE: string,
  MRTYDATE: string,
  PAYMENTDATE: string,
  ISSUERNAME: string,
  BONDTYPE: string,
  PAYDAY: string,
  COUPONTYPE: string,
  PAYTYPE: string,
  PAYPERYEAR: number,
  CURRENCY: string,
  SYQX: number
}>{
  let marketstr = 'SZ'
  if(market == '1'){
    marketstr = 'SH'
  }
  let back = await $.ajax({
    url: api_url.getUrl('old_datacenter') + 'EM_MutiSvcExpandInterface/api/js?type=ZQ_JBXX&token=70f12f2f4f091e459a279469fe49eca5&callback=?',
    type: 'GET',
    dataType: 'json',
    data: {
      filter: "(BONDCODE='" + code + "')(TEXCH='CNSE" + marketstr + "')"
    }
  })

  if(back && back instanceof Array && back.length > 0){
    return back[0]
  }
  return null
}


/**
 * 获取可转债券信息_新接口
 * @param market 市场
 * @param code 代码
 * @returns 
 */
export async function getBondKzzInfoNew(market:string, code:string):Promise<null|{
  EXECUTE_PRICE_SH:  null | number,
  EXECUTE_START_DATESH: null | string,
  EXECUTE_START_DATEHS: null | string,
  EXECUTE_PRICE_HS: null | string,
  RECORD_DATE_SH: null | string,
  CONVERT_STOCK_CODE: string,
  EXECUTE_REASON_SH:  null | string,
  REDEEM_TYPE:  null | string,
  /** 中签号公布日 */
  BOND_START_DATE: null | string,
  SECURITY_NAME_ABBR: string,
  /** 申购日期 */
  PUBLIC_START_DATE: null | string,
  CORRECODE: null | string,
  /** 认购代码 */
  CORRECODEO: null | string,
  /** 上市日期 */
  LISTING_DATE: null | string
}>{
  try {
    let marketstr = 'SZ'
    if(market == '1') marketstr = 'SH'

    let back = await $.ajax({
      url: `${api_url.getUrl('datacenter4')}api/data/v1/get?callback=?`,
      type: 'GET',
      dataType: 'json',
      data: {
        reportName: 'RPT_BOND_CB_LIST',
        columns: 'EXECUTE_PRICE_SH,EXECUTE_START_DATESH,EXECUTE_START_DATEHS,EXECUTE_PRICE_HS,RECORD_DATE_SH,CONVERT_STOCK_CODE,EXECUTE_REASON_SH,REDEEM_TYPE,BOND_START_DATE,SECURITY_NAME_ABBR,PUBLIC_START_DATE,CORRECODE,CORRECODEO,LISTING_DATE',
        quoteColumns: '',
        filter: `(SECUCODE="${code}.${marketstr}")`,
        pageNumber: 1,
        pageSize: 1,
        sortTypes: '',
        sortColumns: '',
        source: `QuoteWeb`,
        client: `WEB`,        
      }
    })

    if(back?.result?.data instanceof Array && back.result.data.length > 0){
      return back.result.data[0]
    }    
  } catch (error) {
    
  }

  return null
}


/**
 * 获取可转债券信息
 * @param market 市场
 * @param market 代码
 */
export async function getBondKzzInfo(code:string):Promise<null|{
  PRICE_SH: string,
  STARTDATE_SH: string,
  STARTDATE_HS: string,
  PRICE_HS: string,
  EXECREASON_SH: number,
  CALLSTYPE_SH: number,
  RECORDATE_SH: string
}>{

  let back = await $.ajax({
    url: api_url.getUrl('old_datacenter') + 'em_mutisvcexpandinterface/api/js/get?callback=?',
    type: 'GET',
    dataType: 'json',
    data: {
      type: 'KZZ_LB',
      token: '70f12f2f4f091e459a279469fe49eca5',
      filter: '(BONDCODE=' + code + ')'
    }
  })

  if(back && back instanceof Array && back.length > 0){
    return back[0]
  }
  return null
}

/**
 * 获取全球经济日历
 * @param datetime 日期 格式:YYYY-MM-DD
 * @returns 
 */
export async function getQQJJRL(datetime: string):Promise<Array<any>>{
  // let back = await $.ajax({
  //   url: api_url.getUrl('datainterface') + 'EM_DataCenter/js.aspx?cb=?',
  //   type: 'GET',
  //   dataType: 'json',
  //   data: {
  //     type: 'GJZB',
  //     sty: 'RLLB',
  //     fd: datetime,
  //     stat: 1,
  //     p: 1,
  //     ps: 200
  //   }
  // })

  // if(back && back instanceof Array && back.length > 0){
  //   return back
  // }
  // return []
  try {
    let back = await $.ajax({
      url: `${api_url.getUrl('datacenter4')}api/data/v1/get?callback=?`,
      type: 'GET',
      dataType: 'json',
      data: {
        reportName: 'RPT_MACRO_FXCALENDAR',
        columns: 'PUBLISH_DATETIME_NEW,PUBLISH_DATE_NEW,INDICATOR_ID,REPORT_DATE,AREA,UNIT,COUNTRY,COUNTRY_CODE,IMPORTANCE,INDICATOR_VALUE,PRE_INDICATOR_VALUE,INDICATOR_NAME,INDICATOR_CODE,PREDICT_VALUE,TREND',
        quoteColumns: '',
        filter: `(PUBLISH_DATE_NEW>='${datetime}')`,
        pageNumber: 1,
        pageSize: 60,
        sortTypes: '',
        sortColumns: '',
        source: `QuoteWeb`,
        client: `WEB`,        
      }
    })

    if(back?.result?.data instanceof Array && back.result.data.length > 0){
      return back.result.data
    }    
  } catch (error) {
    
  }

  return []

}

/** 期货市场和数据期货市场对应 */
export function getDataQihuoMarket(market:string){
  if(market == '113'){ // 上期所
    return '069001005'
  }
  else if(market == '114'){ //大商所
    return '069001007'
  }
  else if(market == '115'){ //郑商所
    return '069001008'
  }
  else if(market == '225'){ //广期所
    return '069001021'
  }  
  else if(market == '142'){ //上期能源
    return '069001016'
  }    
  else if(market == '8'){ //中金所
    return '069001009'
  }
  else if(market == '220'){ //中金所
    return '069001009'
  }    
  return null
}

/**
 * 根据行情的期货代码获取数据那边的期货代码
 * @param code 期货行情代码
 * @returns 
 */
export async function getQihuoDataCode(market: string, code:string):Promise<null | {
  data_code: string,
  data_name: string,
}> {
  try {
    if(market == '8'){ //中金所
      let back = await $.ajax({
        url: `${api_url.getUrl('qihuo_static')}redis?msgid=8all&callbackName=?`,
        type: 'GET',
        dataType: 'json',
        data: {

        }
      })

      if(back instanceof Array){
        let finditem = back.find(v=>v.code == code)
        if(finditem){
          return {
            data_code: finditem.name,
            data_name: finditem.name
          }
        }
      }        
      
    }
    else{
      let back = await $.ajax({
        url: `${api_url.getUrl('datacenter4')}api/data/v1/get?callback=?`,
        type: 'GET',
        dataType: 'json',
        data: {
          reportName: 'RPTA_WEB_QHZSSHQ_CODESNEW',
          columns: 'CODE,HQCODE,NAME,SECUCODE',
          quoteColumns: '',
          filter: '(HQCODE="' + code + '")',
          pageNumber: 1,
          pageSize: 2,
          sortTypes: -1,
          sortColumns: 'CODE',
          source: 'WEB',
          client: 'WEB'
        }
      })

      if(back?.result?.data instanceof Array){
        return {
          data_code: back.result.data[0].CODE,
          data_name: back.result.data[0].NAME
        }
      }        
    }
  
  } catch (error) {
    console.error(error)
  }

  return null
}

/**
 * 获取期货最新成交日期
 * @param datetime 日期 格式:YYYY-MM-DD
 * @returns 
 */
export async function getQihuoLHBDate(market:string):Promise<null|string>{

  let datamarket = getDataQihuoMarket(market)

  try {
    let back = await $.ajax({
      url: `${api_url.getUrl('datacenter4')}api/data/v1/get?callback=?`,
      type: 'GET',
      dataType: 'json',
      data: {
        reportName: `RPT_NEWDATE_FUTU_BYMARKET`,
        columns: `NEW_DATE,TRADE_MARKET_CODE`,
        source: `QuoteWeb`,
        client: `WEB`,
        sortColumns: `REPORT_DATE`,
        filter: `(TRADE_MARKET_CODE="${datamarket}")`,
        sortTypes: '',
        pageSize: 1
      }
    })

    if(back?.result?.data instanceof Array && back.result.data[0].NEW_DATE){
      return back.result.data[0].NEW_DATE.substring(0, 10)
    }    
  } catch (error) {
    
  }

  return null
}


export interface type_qihuo_lhb{
    cjl: {
        company_code: string;
        company_name: string;
        cjl: string;
        zj: string;
    }[];
    dtcc: {
        company_code: string;
        company_name: string;
        cjl: string;
        zj: string;
    }[];
    ktcc: {
        company_code: string;
        company_name: string;
        cjl: string;
        zj: string;
    }[];
    lhb_date: string,
    zuhli_code_datacenter:string
}

/**
 * 获取期货龙虎榜
 * @param market 
 * @returns 
 */
export async function getQihuoLHB(market:string, code:string, vcode: string):Promise<null|type_qihuo_lhb>{
  // console.info(111)
  
  //种类是否大写，目前数据中心只有广期所，上期能源需要小写，其他市场大写
  let datacenter_vcode = vcode.toUpperCase()
  if(market == '142' || market == '225') datacenter_vcode = datacenter_vcode.toLowerCase()

  let datamarket = getDataQihuoMarket(market)
  let lhb_date = await getQihuoLHBDate(market)
  let zuhli_code_datacenter = await getDataZhuli(datacenter_vcode) //获取主力代码
  //如果没有获取到主力代码,则获取最新的合约代码
  if(zuhli_code_datacenter == null){
    zuhli_code_datacenter = await getDataNewHY(datacenter_vcode) //获取最新合约代码
  }
  
  // console.info(market, datamarket, lhb_date, zuhli_code_datacenter)
  

  if(!datamarket || !lhb_date || zuhli_code_datacenter == null){
    return null
  }

  try {
    /** 成交量龙虎榜,多头持仓龙虎榜,空头持仓龙虎榜  */
    let [
      cjl,
      dtcc,
      ktcc
    ] = await Promise.all([
      $.ajax({
        url: `${api_url.getUrl('datacenter4')}api/data/v1/get?callback=?`,
        type: 'GET',
        dataType: 'json',
        data: {
          reportName: 'RPT_FUTU_DAILYPOSITION',
          columns: 'SECUCODE,SECURITY_CODE,SECURITY_INNER_CODE,TRADE_MARKET_CODE,TRADE_DATE,ORG_CODE,MEMBER_NAME_ABBR,VOLUME,VOLUME_CHANGE,VOLUMERANK,VOLUME_RANK',
          quoteColumns: '',
          filter: `(SECURITY_CODE="${zuhli_code_datacenter}")(TRADE_DATE='${lhb_date}')(VOLUMERANK<>9999)(TYPE="0")`,
          sortTypes: 1,
          sortColumns: 'VOLUMERANK',
          source: 'QuoteWeb',
          client: 'WEB',
        }
      }),
      $.ajax({
        url: `${api_url.getUrl('datacenter4')}api/data/v1/get?callback=?`,
        type: 'GET',
        dataType: 'json',
        data: {
          reportName: 'RPT_FUTU_DAILYPOSITION',
          columns: 'SECUCODE,SECURITY_CODE,SECURITY_INNER_CODE,TRADE_MARKET_CODE,TRADE_DATE,ORG_CODE,MEMBER_NAME_ABBR,LONG_POSITION,LP_CHANGE,LPRANK,LP_RANK',
          quoteColumns: '',
          filter: `(SECURITY_CODE="${zuhli_code_datacenter}")(TRADE_DATE='${lhb_date}')(LPRANK <>9999)(TYPE="0")`,
          sortTypes: 1,
          sortColumns: 'LPRANK ',
          source: 'QuoteWeb',
          client: 'WEB',
        }
      }), 
      $.ajax({
        url: `${api_url.getUrl('datacenter4')}api/data/v1/get?callback=?`,
        type: 'GET',
        dataType: 'json',
        data: {
          reportName: 'RPT_FUTU_DAILYPOSITION',
          columns: 'SECUCODE,SECURITY_CODE,SECURITY_INNER_CODE,TRADE_MARKET_CODE,TRADE_DATE,ORG_CODE,MEMBER_NAME_ABBR,SHORT_POSITION,SP_CHANGE,SPRANK,SP_RANK',
          quoteColumns: '',
          filter: `(SECURITY_CODE="${zuhli_code_datacenter}")(TRADE_DATE='${lhb_date}')(SPRANK <>9999)(TYPE="0")`,
          sortTypes: 1,
          sortColumns: 'SPRANK',
          source: 'QuoteWeb',
          client: 'WEB',
        }
      }) 
    ])

    return {
      cjl: (cjl as any).result.data.map((v: any)=>{
        return {
          company_code: v.ORG_CODE,
          company_name: v.MEMBER_NAME_ABBR,
          cjl: v.VOLUME,
          zj: v.VOLUME_CHANGE === null ? 0 : v.VOLUME_CHANGE
        }
      }),
      dtcc: (dtcc as any).result.data.map((v: any)=>{
        return {
          company_code: v.ORG_CODE,
          company_name: v.MEMBER_NAME_ABBR,
          cjl: v.LONG_POSITION,
          zj: v.LP_CHANGE === null ? 0 : v.LP_CHANGE
        }
      }),
      ktcc: (ktcc as any).result.data.map((v: any)=>{
        return {
          company_code: v.ORG_CODE,
          company_name: v.MEMBER_NAME_ABBR,
          cjl: v.SHORT_POSITION,
          zj: v.SP_CHANGE === null ? 0 : v.SP_CHANGE
        }
      }),
      lhb_date,
      zuhli_code_datacenter
    }
    
  } catch (error) {
    
  }

  // let back = await $.ajax({
  //   url: api_url.getUrl('datainterface') + 'EM_DataCenter/js.aspx?cb=?',
  //   type: 'GET',
  //   dataType: 'json',
  //   data: {
  //     type: 'QHCC',
  //     sty: 'QHSYCC',
  //     stat: 3,
  //     fd: lhb_date,
  //     mkt: datamarket,
  //     code: zuhli_code_datacenter,
  //     sc: vcode.toUpperCase()
  //   }
  // })

  // //处理返回数据
  // function dealData(input:Array<string>, zjindex:number){
  //   return input.map(v=>{
  //     let data = v.split(',')
  //     return {
  //       company_code: data[0],
  //       company_name: data[1],
  //       cjl: data[2],
  //       zj: data[zjindex]
  //     }
  //   })
  // }

  // if(back instanceof Array && back[0] && back[0]?.['成交量龙虎榜'] && back[0]?.['多头持仓龙虎榜'] && back[0]?.['空头持仓龙虎榜']){
  //   return {
  //     cjl: dealData(back[0]['成交量龙虎榜'], 3),
  //     dtcc: dealData(back[0]['多头持仓龙虎榜'], 4),
  //     ktcc: dealData(back[0]['空头持仓龙虎榜'], 4),
  //     lhb_date,
  //     zuhli_code_datacenter
  //   }
  // }
  return null
}

/**
 * 获取数据中心代码规则，例如 300059.SZ 831445.BJ
 * @param market 
 * @param code 
 */
export function getDataCenterCode(market:string, code: string, f111: number, f181:number){
  if(market == '0'){
    if(f111 == 81 && f181 & 2048){ //北交所
      return code + '.BJ'
    }
    return code + '.SZ'
  }
  else if(market == '1'){
    return code + '.SH'
  }
  return ''
}

/**
 * 获取利润趋势
 * @param datacode //数据代码 如 300059.SZ 831445.BJ
 * @returns 
 */
export async function LRQS(market: string | null, code:string, thisstocktype?: StockType):Promise<Array<{
  REPORT_DATE: string,
  NET_PROFIT_Q: number,
}>> {

  var datacode = code + '.SH'
  if(market == '0') datacode = code + '.SZ'
  if(thisstocktype && thisstocktype == StockType.北证主板A股) datacode = code + '.BJ'
  if(market == null) datacode = code


  try {
    let back = await $.ajax({
      url: `${api_url.getUrl('datacenter4')}api/data/v1/get?callback=?`,
      type: 'GET',
      dataType: 'json',
      data: {
        reportName: `RPT_F10_FN_QUARTER`,
        columns: `SECURITY_CODE,REPORT_DATE,NET_PROFIT_Q`,
        source: `QuoteWeb`,
        client: `WEB`,
        sortColumns: `REPORT_DATE`,
        filter: `(SECUCODE="${datacode}")`,
        sortTypes: -1,
        pageSize: 12
      }
    })

    if(back?.result?.data instanceof Array){
      return back.result.data
    }    
  } catch (error) {
    
  }

  return []
}



/**
 * 流通股东（家数）
 * @param datacode 数据代码 类似于 300059.SZ 831445.BJ
 * @returns 
 */
export async function LTGDJS(datacode:string):Promise<Array<{
  HOLDER_PUBLIC_NUM: number,
  HOLDER_PUBLIC_NUMMM: number,
  HOLDER_PRIVATE_NUM: number,
  HOLDER_PRIVATE_NUMMM: number,
  HOLDER_SOCIAL_NUM: number,
  HOLDER_SOCIAL_NUMMM: number,
  HOLDER_QFII_NUM: number,
  HOLDER_QFII_NUMMM: number,
}>> {
  try {
    let back = await $.ajax({
      url: `${api_url.getUrl('datacenter4')}api/data/v1/get?callback=?`,
      type: 'GET',
      dataType: 'json',
      data: {
        reportName: `RPT_F10_HOLDERORGNUM`,
        columns: `SECUCODE,END_DATE,ORG_CODE,HOLDER_PUBLIC_NUM,HOLDER_PUBLIC_NUMMM,HOLDER_PRIVATE_NUM,HOLDER_PRIVATE_NUMMM,HOLDER_SOCIAL_NUM,HOLDER_SOCIAL_NUMMM,HOLDER_QFII_NUM,HOLDER_QFII_NUMMM`,
        filter: `(SECUCODE="${datacode}")`,
        pageNumber: 1,
        pageSize: 2,
        sortTypes: -1,
        sortColumns: `END_DATE`,
        source: `QuoteWeb`,
        client: `WEB`,
      }
    })

    if(back?.result?.data instanceof Array){
      return back.result.data
    }    
  } catch (error) {
    
  }

  return []
}

/**
 * 流通股东（股东人数情况）
 * @param datacode 数据代码 类似于 300059.SZ 831445.BJ
 * @returns 
 */
export async function LTGDGD(datacode:string):Promise<Array<{
  END_DATE: string,
  HOLDER_TOTAL_NUM: number,
  TOTAL_NUM_RATIO: number
}>> {
  try {
    let back = await $.ajax({
      url: `${api_url.getUrl('datacenter4')}api/data/v1/get?callback=?`,
      type: 'GET',
      dataType: 'json',
      data: {
        reportName: `RPT_F10_EH_HOLDERNUM`,
        columns: `SECUCODE,SECURITY_CODE,END_DATE,HOLDER_TOTAL_NUM,TOTAL_NUM_RATIO`,
        filter: `(SECUCODE="${datacode}")`,
        pageNumber: 1,
        pageSize: 2,
        sortTypes: -1,
        sortColumns: `END_DATE`,
        source: `QuoteWeb`,
        client: `WEB`,
      }
    })

    if(back?.result?.data instanceof Array){
      return back.result.data
    }    
  } catch (error) {
    
  }

  return []
}

/**
 * 融资融券总况
 * @returns 
 */
export async function RZRQSummary():Promise<Array<{
  TRADE_DATE: string,
  MARKET: string,
  FIN_BALANCE: number,
  LOAN_BALANCE: number,
  MARGIN_BALANCE: number,
  FIN_BUY_AMT: number,
  BOARD_CODE: string,
}>> {
  try {
    let back = await $.ajax({
      url: `${api_url.getUrl('datacenter4')}api/data/get?callback=?`,
      type: 'GET',
      dataType: 'json',
      data: {
        type: 'RPT_MARGIN_MARGINPROFILE',
        sty: 'TRADE_DATE,MARKET,BOARD_CODE,FIN_BALANCE,LOAN_BALANCE,MARGIN_BALANCE,FIN_BUY_AMT',
        extraCols: '',
        filter: '',
        p: 1,
        ps: 5,
        sr: '',
        st: '',
        token: '',
        var: '',
        source: 'QuoteWeb',
        client: 'WEB',
      }
    })

    if(back?.result?.data instanceof Array && back.result.data.length > 0){
      return back.result.data
    }    
  } catch (error) {
    
  }

  return []
}

/**
 * 个股日历类型
 */
export enum rltype{
  停复牌 = '023',
  龙虎榜 = '012',
  大宗交易 = '002',
  公告 = '019',
  研报 = '020',
  机构调研 = '005',
  股东增减持日 = '016',
  限售解禁日 = '017',
  高管持股 = '026',
  高管关联人持股 = '011',
  预约披露日 = '006',
  业绩预告 = '025',
  业绩快报 = '010',
  业绩报表 = '007',
  股本变动 = '003',
  新股申购 = '024',
  分红 = '004',
  股东大会 = '021',
  股东户数 = '009',
  并购重组 = '013',
  公司投资 = '008',
  股权质押 = '001',
  委托理财 = '014',
  重大合同 = '015',
  股票回购 = '022',
  关联交易 = '018',
}

/**
 * 个股日历
 * @returns 
 */
export async function GGRL(code:string, pagesize = 10):Promise<Array<any>> {

  let typelist_str = Object.values(rltype).map(v=>'"' + v + '"').join(',')

  try {
    let back = await $.ajax({
      url: `${api_url.getUrl('datacenter4')}api/data/v1/get?callback=?`,
      type: 'GET',
      dataType: 'json',
      data: {
        reportName: 'RPT_STOCKCALENDAR',
        columns: 'SECUCODE,SECURITY_CODE,SECURITY_INNER_CODE,ORG_CODE,NOTICE_DATE,INFO_CODE,EVENT_TYPE,EVENT_TYPE_CODE,LEVEL1_CONTENT,CHANGE_RATE,CLOSE_PRICE',
        quoteColumns: '',
        filter: '(SECURITY_CODE="' + code + '")(EVENT_TYPE_CODE in (' + typelist_str + '))(NOTICE_DATE<=\'' + dayjs().add(90,'day').format('YYYY-MM-DD') + '\')',
        pageNumber: '',
        pageSize: pagesize,
        sortTypes: -1,
        sortColumns:  'NOTICE_DATE ',
        source: 'QuoteWeb',
        client: 'WEB',
      }
    })

    if(back?.result?.data instanceof Array){
      return back.result.data.map((v: any)=>{
        return {
          ...v,
          type: _.findKey(rltype, (o)=>o == v.EVENT_TYPE_CODE)
        }
      })
    }    
  } catch (error) {
    
  }

  return []
}


/**
 * 更名信息
 * @returns 
 */
export async function StockChangeName(market: string, code:string, pagesize = 100):Promise<Array<{
  change_date: string,
  name: string
}>> {

  let datacode = code  + '.SZ'
  if(market == '1') datacode = code + '.SH'

  try {
    let back = await $.ajax({
      url: `${api_url.getUrl('datacenter4')}api/data/v1/get?callback=?`,
      type: 'GET',
      dataType: 'json',
      data: {
        reportName: 'RPT_IPO_ABSTOCK',
        columns: 'SECURITY_CODE,CHANGE_DATE,CHANGE_AFTER_FN,CHANGE_AFTER_AB,TRADE_MARKET_TYPE,RANK,SECUCODE',
        quoteColumns: '',
        filter: '(SECUCODE="' + datacode + '")',
        pageNumber: 1,
        pageSize: pagesize,
        sortTypes: 1,
        sortColumns: 'CHANGE_DATE',
        source: 'QuoteWeb',
        client: 'WEB',
      }
    })

    if(back?.result?.data instanceof Array){
      return back.result.data.map((v: any)=>{
        return {
          change_date: v.CHANGE_DATE,
          name: v.CHANGE_AFTER_FN
        }
      })
    }    
  } catch (error) {
    
  }

  return []
}



/**
 * 根据市场和品种代码获取主力品种 期货接口
 * @returns 数组0 是主力 1 是次主力
 */
export async function getZhuli(market: string, vcode: string):Promise<Array<{
  mktid: string, //113,
  vname2: string, //"沪金",
  typeid: string, //5,
  code: string, //"au2202",
  vcode: string //"AU",
}>>{

  try {
    let back = await $.ajax({
      url: `${api_url.getUrl('qihuo_api')}static/main/custom/${market}_${vcode}?callbackName=?`,
      type: 'GET',
      dataType: 'json',
      data: {
        maintype: 2
      }
    })

    if(back?.list){
      return back.list.map((v: any)=>{
        return {
          code: v.ucode.substring(v.ucode.indexOf('|') + 1),
          ...v
        }
      })
    }    
    return []
  } catch (error) {
    console.error(error)
    return []
  } 
}

/**
 * 根据品种获取主力代码，数据接口
 * @param vcode 
 * @param upper 
 * @returns 
 */
export async function getDataZhuli(vcode: string):Promise<string|null>{

  try {
    let back = await $.ajax({
      url: `${api_url.getUrl('datacenter4')}api/data/v1/get?callback=?`,
      type: 'GET',
      dataType: 'json',
      data: {
        reportName: 'RPT_FUTU_POSITIONCODE',
        columns: 'TRADE_CODE,TRADE_TYPE,SECURITY_CODE',
        quoteColumns: '',
        filter: `(TRADE_CODE="${vcode}")(IS_MAINCODE="1")`,
        pageNumber: 1,
        pageSize: 2,
        sortTypes: '',
        sortColumns: '',
        source: 'QuoteWeb',
        client: 'WEB',
      }
    })

    if(back?.result?.data instanceof Array && back.result.data[0].SECURITY_CODE){
      return back.result.data[0].SECURITY_CODE
    }    
  } catch (error) {
    console.error(error)    
  }

  return null
}

/**
 * 根据品种获取最新合约代码，数据接口
 * @param vcode 
 * @param upper 
 * @returns 
 */
export async function getDataNewHY(vcode: string):Promise<string|null>{

  try {
    let back = await $.ajax({
      url: `${api_url.getUrl('datacenter4')}api/data/v1/get?callback=?`,
      type: 'GET',
      dataType: 'json',
      data: {
        reportName: 'RPT_FUTU_POSITIONCODE',
        columns: 'TRADE_CODE,TRADE_TYPE,SECURITY_CODE',
        quoteColumns: '',
        filter: `(TRADE_CODE="${vcode}")(IS_MAINCODE<>"1")`,
        pageNumber: 1,
        pageSize: 2,
        sortTypes: '',
        sortColumns: '',
        source: 'QuoteWeb',
        client: 'WEB',
      }
    })

    if(back?.result?.data instanceof Array && back.result.data[0].SECURITY_CODE){
      return back.result.data[0].SECURITY_CODE
    }    
  } catch (error) {
    console.error(error)    
  }

  return null
}


/**
 * 港股公司回购
 * @param code
 * @returns 
 */
export async function HK_GSHG(code: string, size = 6):Promise<Array<{
  TRADE_DATE: string, 
  REPO_NUM: string, 
  AVG_PRICE: number, 
}>>{

  try {
    let back = await $.ajax({
      url: `${api_url.getUrl('datacenter4')}web/api/data/v1/get?callback=?`,
      type: 'GET',
      dataType: 'json',
      data: {
        reportName: 'RPT_HKCH_COMPANYREPO',
        columns: 'SECURITY_CODE,SECURITY_NAME_ABBR,TRADE_DATE,REPO_NUM,HIGH_PRICE,LOW_PRICE,REPO_AMT,AVG_PRICE,SECUCODE',
        filter: `(SECUCODE="${code}.HK")`,
        pageNumber: 1,
        pageSize: size,
        sortTypes: -1,
        sortColumns: 'TRADE_DATE',
        source: `QuoteWeb`,
        client: `WEB`,        
      }
    })

    if(back?.result?.data instanceof Array){
      return back.result.data
    }    
  } catch (error) {
    
  }

  return []
}

/**
 * 港股机构评级
 * @param code
 * @returns 
 */
export async function HK_JGPJ(code: string, size = 5):Promise<Array<{
  ORG_NAME_ABBR: string, 
  RATING_NAME: string, 
  PR_UPPER: string, 
}>>{

  try {
    let back = await $.ajax({
      url: `${api_url.getUrl('datacenter4')}web/api/data/v1/get?callback=?`,
      type: 'GET',
      dataType: 'json',
      data: {
        reportName: 'RPT_HK_ORGRATING',
        columns: 'HK_ORGRATING',
        filter: `(SECUCODE="${code}.HK")(RATING_NAME<>"null")`,
        pageNumber: 1,
        pageSize: size,
        sortTypes: -1,
        sortColumns: 'PUBLISH_DATE',
        source: `QuoteWeb`,
        client: `WEB`,        
      }
    })

    if(back?.result?.data instanceof Array){
      return back.result.data
    }    
  } catch (error) {
    
  }

  return []
}

// /**
//  * 港股沽空记录
//  * @param code
//  * @returns 
//  */
// export async function HK_GKJL(code: string, size = 9):Promise<Array<{
//   nums: string, 
//   sum: string, 
//   zb: string, 
// }>>{

//   let back = await $.ajax({
//     url: api_url.getUrl('datainterface') + 'EM_DataCenter/js.aspx?cb=?',
//     type: 'GET',
//     dataType: 'json',
//     data: {
//       type: 'HKPD',
//       sty: 'HKSYPD',
//       stat: 2,
//       code: code,
//       p: 1,
//       ps: size
//     }
//   })

//   if(back && back instanceof Array && back.length > 0 && back[0]?.stats != false){
//     return back.map(v=>{
//       let arr = v.split(',')
//       return {
//         nums: arr[3], 
//         sum: arr[4], 
//         zb: arr[6], 
//       }
//     })
//   }
//   return []
// }

/**
 * 港股沽空记录-新接口
 * @param code //00700
 * @returns 
 */
export async function HK_GKJL(code:string, size=9):Promise<Array<{
  SECURITY_CODE: string,
  SECURITY_NAME_ABBR: string,
  TRADE_DATE: string,
  SHORT_SELLING_AMT: number,
  SHORT_SELLING_RATIO: number,
  SHORT_SELLING_SHARES: number
}>> {
  try {
    let back = await $.ajax({
      url: `${api_url.getUrl('datacenter4')}web/api/data/v1/get?callback=?`,
      type: 'GET',
      dataType: 'json',
      data: {
        reportName: 'RPT_HK_SHORTSELLING',
        columns: 'SECURITY_CODE,SECURITY_NAME_ABBR,TRADE_DATE,SHORT_SELLING_AMT,SHORT_SELLING_RATIO,SHORT_SELLING_SHARES',
        filter: `(SECUCODE="${code}.HK")(TRADE_DATE>='${dayjs().subtract(1,'year').format('YYYY-MM-DD')}')`,
        pageNumber: 1,
        pageSize: size,
        sortTypes: -1,
        sortColumns: 'TRADE_DATE',
        source: `QuoteWeb`,
        client: `WEB`,        
      }
    })

    if(back?.result?.data instanceof Array){
      return back.result.data
    }    
  } catch (error) {
    
  }

  return []
}

// /**
//  * 港股公司简介
//  * @param code
//  * @returns 
//  */
// export async function HK_GSJJ(code: string):Promise<null | {
//     DIVIDENDYIELD: string, //"0",
//     INDUSTRY: string, //"软件服务",
//     HKEQUITY: string, //"9603803893",
//     COMPANYWEBSITE: string, //"www.tencent.com",
//     TOTALEQUITY: string, //"9603803893",
//     AVERAGEOFTHIRTYDAYS: string, //"474.123333333333",
//     COMPANYTEL: string, //"+852 2179-5122",
//     COMPANYNAME: string, //"腾讯控股有限公司",
//     LOTSIZE: string, //"100",
//     AMPLITUDEOFONEWEEK: string, //"2435.74-2561.41",
//     SECURITYCODE: string, //"00700.HK",
//     COMPANYCODE: string, //"10001997",
//     TDATE: string, //"2021-12-15",
//     PERATIO: string, //"19.084000709224",
//     AMPLITUDEOFEIGHTWEEK: string, //"2383.55-2729.16"
// }>{

//   let back = await $.ajax({
//     url: api_url.getUrl('datainterface') + 'EM_DataCenter/js.aspx?cb=?',
//     type: 'GET',
//     dataType: 'json',
//     data: {
//       type: 'GJZB',
//       sty: 'HKF10',
//       code: code
//     }
//   })

//   if(back && back instanceof Array && back.length > 0 && back[0]?.stats != false){
//     return back[0]
//   }
//   return null
// }


/**
 * 港股-港股公司简介-新接口
 * @param code //00700
 * @returns 
 */
export async function HK_GSJJ_NEW(code:string):Promise<any> {
  try {
    let back = await $.ajax({
      url: `${api_url.getUrl('datacenter4')}web/api/data/v1/get?callback=?`,
      type: 'GET',
      dataType: 'json',
      data: {
        reportName: 'RPT_HK_ORGPROFILE',
        columns: 'SECUCODE,SECURITY_CODE,ORG_CODE,SECURITY_INNER_CODE,SECURITY_NAME_ABBR,ORG_NAME,ORG_EN_ABBR,INDUSTRY,PE,TOTAL_EQUITY,AVERAGEPRICE_30,HK_SHARES,ORG_WEB,TRADE_UNIT',
        quoteColumns: '',
        filter: `(SECUCODE="${code}.HK")`,
        pageNumber: 1,
        pageSize: 200,
        sortTypes: '',
        sortColumns: '',
        source: `QuoteWeb`,
        client: `WEB`,        
      }
    })

    if(back?.result?.data instanceof Array){
      return back.result.data[0]
    }    
  } catch (error) {
    
  }

  return null
}





/**
 * 港股-机构增减持
 * @param code //00700
 * @returns 
 */
export async function HK_JGZJC(code:string):Promise<Array<{
  HOLDER_NAME: string,
  CHANGE_TYPE: string,
  CHANGE_SHARES: number,
}>> {
  try {
    let back = await $.ajax({
      url: `${api_url.getUrl('datacenter4')}web/api/data/v1/get?callback=?`,
      type: 'GET',
      dataType: 'json',
      data: {
        reportName: 'RPT_HKF10_SH_EQUITYCHG',
        columns: 'HOLDER_NAME,CHANGE_TYPE,CHANGE_SHARES',
        quoteColumns: '',
        filter: '(SECUCODE="' + code + '.HK")',
        pageNumber: 1,
        pageSize: 6,
        sortTypes: '-1,-1',
        sortColumns: 'EVENT_DATE,AFTER_HOLD_SHARES',
        source: `QuoteWeb`,
        client: `WEB`,        
      }
    })

    if(back?.result?.data instanceof Array){
      return back.result.data
    }    
  } catch (error) {
    
  }

  return []
}


/**
 * 股本结构
 * @param code //00700
 * @returns 
 */
export async function GBJG(market: string, code:string, size = 4, thisstocktype?: StockType):Promise<Array<{
  SECUCODE: string,
  END_DATE: string,
  LIMITED_SHARES: string | number,
  UNLIMITED_SHARES: string | number,
  TOTAL_SHARES: string | number
}>> {

  var datacode = code + '.SH'
  if(market == '0') datacode = code + '.SZ'
  if(thisstocktype && thisstocktype == StockType.北证主板A股) datacode = code + '.BJ'

  try {
    var back = await $.ajax({
      url: `${api_url.getUrl('datacenter4')}web/api/data/v1/get?callback=?`,
      type: 'GET',
      dataType: 'json',
      data: {
        reportName: 'RPT_F10_EH_EQUITY',
        columns: 'SECUCODE,END_DATE,LIMITED_SHARES,UNLIMITED_SHARES,TOTAL_SHARES',
        filter: '(SECUCODE="' + datacode + '")',
        sortColumns: 'END_DATE',
        sortTypes: -1,
        pageNumber: 1,
        pageSize: size,
        source: `QuoteWeb`,
        client: `WEB`,
        nilType: 'dashType'
      }
    })

    if(back?.result?.data instanceof Array){
      return back.result.data
    }    
  } catch (error) {
    
  }

  return []
}


/**
 * 公司资料
 * @param code //00700
 * @returns 
 */
export async function GSZL(market: string, code:string, thisstocktype?: StockType):Promise<{
  ORG_NAME: string,
  REGIONBK: string,
  EM2016: string,
  MAIN_BUSINESS: string
}|null> {

  var datacode = code + '.SH'
  if(market == '0') datacode = code + '.SZ'
  if(thisstocktype && thisstocktype == StockType.北证主板A股) datacode = code + '.BJ'

  try {    
    var back = await $.ajax({
      url: `${api_url.getUrl('datacenter4')}web/api/data/v1/get?callback=?`,
      type: 'GET',
      dataType: 'json',
      data: {
        reportName: 'RPT_F10_ORG_BASICINFO',
        columns: 'ORG_NAME,REGIONBK,EM2016,MAIN_BUSINESS',
        filter: '(SECUCODE="' + datacode + '")',
        sortColumns: '',
        sortTypes: '',
        pageNumber: 1,
        pageSize: 1,
        source: `QuoteWeb`,
        client: `WEB`,
        nilType: 'dashType'
      }
    })

    if(back?.result?.data instanceof Array && back.result.data.length > 0){
      return back.result.data[0]
    }    
  } catch (error) {
    
  }

  return null
}


/**
 * 收入构成
 * @param code //00700
 * @returns 
 */
export async function SRGC(market: string, code:string, thisstocktype?: StockType):Promise<Array<{
  REPORT_NAME: string,
  ITEM_NAME: string,
  MAIN_BUSINESS_INCOME: number | string,
  MBI_RATIO: number | string
}>> {

  var datacode = code + '.SH'
  if(market == '0') datacode = code + '.SZ'
  if(thisstocktype && thisstocktype == StockType.北证主板A股) datacode = code + '.BJ'

  try {
    var back = await $.ajax({
      url: `${api_url.getUrl('datacenter4')}web/api/data/v1/get?callback=?`,
      type: 'GET',
      dataType: 'json',
      data: {
        reportName: 'RPT_F10_FN_MAINOPNEWEST',
        columns: 'REPORT_NAME,ITEM_NAME,MAIN_BUSINESS_INCOME,MBI_RATIO',
        filter: '(SECUCODE="' + datacode + '")(MAINOP_TYPE="2")',
        sortColumns: '',
        sortTypes: '',
        pageNumber: 1,
        pageSize: 10,
        source: `QuoteWeb`,
        client: `WEB`,
        nilType: 'dashType'
      }
    })

    if(back?.result?.data instanceof Array){
      return back.result.data
    }    
  } catch (error) {
    
  }

  return []
}