import React,{useState, useEffect} from "react"
import { rltype, GGRL } from '../../../modules/datacenter/web'
import { sendStat } from "../../../modules/bigdata_stat"
import './index.less'


/**
 * 个股日历bar
 * @returns 
 */
export function GGRLBar(props:{
  code: string,
  market: string
}){

  const [showdata, SetShowData] = useState([] as Array<any>)

  useEffect(()=>{
    ;(async()=>{
      let back = await GGRL(props.code)
      if(back.length > 0){
        SetShowData(back)
                 $(".ggrlbar_list").vTicker({
                        showItems: 1,
                        height: 26
                    });
      }
    })();
  },[])

  return (
    <div className="ggrlbar self_clearfix" onClick={()=>{sendStat('DIV', 'Click', 'webjdb.ggrl.ggrlmk')}}>
      <div className="t">
        <a href={`//data.eastmoney.com/stockcalendar/${props.code}.html`} target="_blank">个股日历</a>
      </div>
      <div className="ggrlbar_list">
        <ul>
          {
            showdata.map((v, index)=>
              <li key={index}>
                <a href={hrefFun(v.EVENT_TYPE_CODE, props.code, props.market)} target="_blank" title={v.LEVEL1_CONTENT}>{v.type}：{v.LEVEL1_CONTENT}</a>
              </li>
            )
          }
        </ul>           
      </div>
   
      <a className="upbtn scrollBtn"></a>
    </div>
  )
}

//个股日历列表跳转链接
export function hrefFun(type: rltype, code: string, market: string, isbj?:boolean) {
    var href = "";
    var marstr = market == "1" ? "SH" : "SZ"
    if(isbj == true) marstr = "BJ"
    switch (type) {
        case rltype.停复牌:
            href = "//data.eastmoney.com/tfpxx/";
            break;
            // 停牌日期
        case rltype.龙虎榜:
            href = "//data.eastmoney.com/stock/lhb/" + code + ".html";
            break;
            // 龙虎榜
        case rltype.大宗交易:
            href = "//data.eastmoney.com/dzjy/detail/" + code + ".html";
            break;
            // 大宗交易
        case rltype.公告:
            href = "//data.eastmoney.com/notices/stock/" + code + ".html";
            break;
            // 公告
        case rltype.研报:
            href = "//data.eastmoney.com/report/" + code + ".html";
            break;
            // 研报
        case rltype.机构调研:
            href = "//data.eastmoney.com/jgdy/gsjsdy/" + code + ".html";
            break;
            // 机构调研
        case rltype.股东增减持日:
            href = "//data.eastmoney.com/executive/gdzjc/" + code + ".html";
            break;
            // 股东增减持日
        case rltype.限售解禁日:
            href = "//data.eastmoney.com/dxf/q/" + code + ".html";
            break;
            // 限售解禁日
        case rltype.高管持股:
            href = "//data.eastmoney.com/executive/" + code + ".html";
            break;
            // 高管持股
        case rltype.高管关联人持股:
            href = "//data.eastmoney.com/executive/" + code + ".html";
            break;
            // 高管关联人持股
        case rltype.预约披露日:
            href = "//data.eastmoney.com/bbsj/" + code + ".html";
            break;
            // 预约披露日
        case rltype.业绩预告:
            href = "//data.eastmoney.com/bbsj/" + code + ".html";
            break;
            // 业绩预告
        case rltype.业绩快报:
            href = "//data.eastmoney.com/bbsj/" + code + ".html";
            break;
            // 业绩快报
        case rltype.业绩报表:
            href = "//data.eastmoney.com/bbsj/" + code + ".html";
            break;
            // 业绩报表
        case rltype.股本变动:
            // href = "//emweb.securities.eastmoney.com/PC_HSF10/CapitalStockStructure/Index?type=web&code=" + (market == "1" ? "sh" + code : "sz" + code);
            href = `//emweb.securities.eastmoney.com/pc_hsf10/pages/index.html?type=web&code=${marstr}${code}&color=b#/gbjg`;
            break;
            // 股本变动
        case rltype.新股申购:
            href = "//data.eastmoney.com/xg/xg/detail/" + code + ".html";
            break;
            // 新股
        case rltype.分红:
            href = "//data.eastmoney.com/yjfp/detail/" + code + ".html";
            break;
            // 分红
        case rltype.股东大会:
            href = "//data.eastmoney.com/gddh/list/" + code + ".html";
            break;
            // 股东大会
        case rltype.股东户数:
            href = "//data.eastmoney.com/gdhs/detail/" + code + ".html";
            break;
            // 股东户数

        case rltype.并购重组:
            href = "//data.eastmoney.com/bgcz/detail/" + code + ".html";
            break;
        //公司投资
        case rltype.公司投资:
            href = " //data.eastmoney.com/gstz/stock/" + code + ".html";
            break;
        //股权质押
        case rltype.股权质押:
            href = "//data.eastmoney.com/gpzy/detail/" + code + ".html";
            break;
        //委托理财
        case rltype.委托理财:
            href = "//data.eastmoney.com/wtlc/detail/" + code + ".html";
            break;
        //重大合同
        case rltype.重大合同:
            href = " //data.eastmoney.com/zdht/detail/" + code + ".html";
            break;
        //股票回购
        case rltype.股票回购:
            href = "//data.eastmoney.com/gphg/" + code + ".html";
            break;
        //关联交易
        case rltype.关联交易:
            href = "//data.eastmoney.com/gljy/detail/" + code + ".html";
            break;
        
    }
    return href;
}