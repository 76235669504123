/**
 * 股吧内嵌页
 */

import React,{useState, useEffect, useLayoutEffect} from "react"
import { getGubaCode } from "../../../modules/guba/gubacode";
import webconfig from "../../../config/web";

export function GubaFrame(props:{
  market: string,
  code: string,
  pagetype?:string,
  gubacode?: string
  height?:number,
  count?:number,
  stock_type?:boolean,
  newpost?:boolean
}){

  const [isBrowser, SetIsBrowser] = useState(false)

  let stock_type = props.stock_type ?? false
  let newpost = props.newpost ?? false
  var guba_code = props.gubacode || getGubaCode(props.market, props.code, props.pagetype)

  useEffect(()=>{
    SetIsBrowser(true)

    if(newpost && window.loadEditor && document.getElementById('gubanewpost')){
      window.loadEditor({
        id: 'gubanewpost',
        code: guba_code
      })
    }     
  }, []) 

  let height = props.height || 451
  let count = props.count || 12

  return (
    <div style={{height: (height + 240) + 'px'}}>
      {
        isBrowser &&
        <iframe loading="lazy" src={`${webconfig.getUrl('gubapage')}?code=${guba_code}&ba_show=false&count=${count}&stock_type=${stock_type}`} frameBorder={0} marginHeight={0} marginWidth={0} style={{ width: 790, height: height }} className="quote_guba_frame" />
      }
      {isBrowser && !newpost &&
        <iframe src={`//gubawebapi2.eastmoney.com/page/fund_editor2/dest/editor.html?s=${guba_code}&width=788&logincb=${encodeURIComponent(self.location.href)}`} scrolling="no" frameBorder={0} marginHeight={0} marginWidth={0} style={{width: 790, height: 240}} />
      }
      <div id="gubanewpost"></div>
    </div> 
  )
}
